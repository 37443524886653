<template>
  <div class="m-modal-review modal-content">
    <SfModal :visible="isVisible" @close="closeModal">
      <form @submit.prevent="submit" class="form">
        <SfInput
          v-model="formData.name"
          name="firstName"
          :label="$t('First name')"
          :required="true"
          :valid="!$v.formData.name.$error"
          :error-message="
            !$v.formData.name.required
              ? $t('Field is required.')
              : $t('Name must have at least 2 letters.')
          "
          class="form__element"
        />
        <SfInput
          v-model="formData.email"
          name="email"
          :label="$t('Email address')"
          :required="true"
          :valid="!$v.formData.email.$error"
          :error-message="
            !$v.formData.email.required
              ? $t('Field is required.')
              : $t('Please provide valid e-mail address.')
          "
          class="form__element"
        />
        <SfInput
          v-model="formData.summary"
          name="summary"
          :label="$t('Summary')"
          :required="true"
          :valid="!$v.formData.summary.$error"
          :error-message="$t('Field is required.')"
          class="form__element"
        />
        <SfInput
          v-model="formData.review"
          name="reviewText"
          :label="$t('Review')"
          :required="true"
          :valid="!$v.formData.review.$error"
          :error-message="$t('Field is required.')"
          class="form__element"
        />
        <SfSelect
          v-model="formData.rating"
          :name="$t('Rating')"
          :label="$t('Rating')"
          required
          :error-message="$t('Field is required.')"
          class="form__element m-modal-review__select"
        >
          <SfSelectOption
            v-for="rate in 5"
            :key="rate"
            :value="rate"
            class="m-modal-review__select-option"
          >
            {{ rate }}
          </SfSelectOption>
        </SfSelect>
        <SfCheckbox
          v-model="formData.isRecommended"
          :label="$t('I recommend this product')"
          class="form__element"
          :error-message="$t('Field is required.')"
        />

        <SfInput
          v-model="formData.location"
          name="reviewText"
          :label="$t('Location')"
          :required="false"
          :error-message="$t('Field is required.')"
          class="form__element"
        />

        <SfButton class="sf-button--full-width form__submit">
          {{ $t("Add review") }}
        </SfButton>
      </form>
    </SfModal>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfInput from '@storefront-ui/vue/src/components/atoms/SfInput/SfInput.vue';
import SfCheckbox from '@storefront-ui/vue/src/components/molecules/SfCheckbox/SfCheckbox.vue';
import SfSelect from '@storefront-ui/vue/src/components/molecules/SfSelect/SfSelect.vue';
import SfModal from '~/theme/components/molecules/SfModal/SfModal.vue';
export default {
  name: 'MModalReview',
  components: { SfModal, SfInput, SfButton, SfCheckbox, SfSelect },
  data () {
    return {
      formData: {
        name: '',
        email: '',
        summary: '',
        review: '',
        rating: '5',
        location: '',
        isRecommended: false
      }
    }
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.user.current;
    }
  },
  mounted () {
    this.fillInUserData()
  },
  methods: {
    closeModal () {
      this.$emit('close', this.modalData.name)
    },
    clearReviewForm () {
      this.formData.name = '';
      this.formData.email = '';
      this.formData.summary = '';
      this.formData.review = '';
      this.$v.$reset();
    },
    fillInUserData () {
      if (this.currentUser) {
        this.formData.name = this.currentUser.firstname;
        this.formData.email = this.currentUser.email;
      }
    },
    async submit () {
      this.$v.$touch();
      if (this.$v.$invalid) return
      try {
        await this.$store.dispatch('stamped-io/add', {
          productId: this.modalData.payload.productSku,
          author: this.formData.name,
          email: this.formData.email,
          location: this.formData.location,
          reviewRating: this.formData.rating,
          reviewTitle: this.formData.summary,
          reviewMessage: this.formData.review,
          reviewRecommendProduct: this.formData.isRecommended,
          productName: this.modalData.payload.productName,
          productSKU: this.modalData.payload.productSku,
          productImageUrl: this.modalData.payload.productImage,
          reviewSource: 'website'
        })
      } catch (err) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'danger',
          message: this.$t('Something went wrong. Try again in a few seconds.'),
          action1: { label: this.$t('OK') }
        });
      }

      /*
      const isReviewCreated = await this.$store.dispatch('review/add', {
        product_id: this.modalData.payload,
        title: this.formData.summary,
        detail: this.formData.review,
        nickname: this.formData.name,
        review_entity: 'product',
        customer_id: this.currentUser ? this.currentUser.id : null
      });

      if (isReviewCreated) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'success',
          message: this.$t('You submitted your review for moderation.'),
          action1: { label: this.$t('OK') }
        });
        this.clearReviewForm()
        this.closeModal()
        return;
      }

      this.$store.dispatch('notification/spawnNotification', {
        type: 'danger',
        message: this.$t('Something went wrong. Try again in a few seconds.'),
        action1: { label: this.$t('OK') }
      });
      */
    }
  },
  validations: {
    formData: {
      name: {
        minLength: minLength(2),
        required
      },
      email: {
        required,
        email
      },
      summary: {
        required
      },
      review: {
        required
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.m-modal-review__rating-header {
  font-weight: var(--font-weight--light);
}
.m-modal-review__select {
  ::v-deep {
    .sf-select__dropdown {
      font-size: var(--font-size--lg);
      font: var(--input-label-font, var(--input-label-font-weight, var(--font-weight--normal)) var(--input-label-font-size, var(--font-size--lg))/var(--input-label-font-line-height, 1) var(--input-label-font-family, var(--font-family--secondary)));
    }
  }
}
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  ::v-deep {
    .sf-modal__container {
      --modal-index: 5000;
    }
  }
}
.form {
  width: 100%;
  &__element {
    margin: var(--spacer-base) 0;
  }
  &__submit {
    margin: var(--spacer-xl) 0 0 0;
  }
}
</style>
