<template>
  <div class="m-modal-decline">
    <SfModal :visible="isVisible">
      <SfHeading :title="$t('We use cookies')" :level="3" />
      <p>{{ $t(defaultContent) }}</p>
      <div class="buttons-wrapper">
        <SfButton class="sf-button--outline sf-button--full-width color-primary" @click="declinedCookies">
          {{ $t("Decline") }}
        </SfButton>
        <SfButton class="sf-button sf-button--full-width color-secondary" @click="accept">
          {{ $t("Accept") }}
        </SfButton>
      </div>
      <template #close>
        &nbsp;
      </template>
    </SfModal>
  </div>
</template>

<script>
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';
import SfModal from '~/theme/components/molecules/SfModal/SfModal.vue';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import { setCookie, removeCookie, approveCookie } from 'theme/helpers';
import { registerModule } from '@vue-storefront/core/lib/modules'
import { GoogleTagManagerModule } from 'src/modules/google-tag-manager'
import { Klaviyo } from 'src/modules/klaviyo'

export default {
  name: 'MModalCheckout',
  components: {
    SfModal,
    SfButton,
    SfHeading
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  computed: {
    defaultContent () {
      return 'We use cookies to keep our sites reliable and secure, measure performance, and deliver personalized shopping experiences.'
    }
  },
  methods: {
    closeModal () {
      this.$emit('close', this.modalData.name)
    },
    goToCheckout () {
      this.$emit('close', this.modalData.name)
      this.$router.push(localizedRoute('/checkout'));
    },
    declinedCookies () {
      approveCookie('declined_cookies')
      this.saveCookies(false)
      this.$bus.$emit('gtm-declined-cookies')
    },
    accept () {
      registerModule(GoogleTagManagerModule);
      registerModule(Klaviyo);
      removeCookie('declined_cookies')
      this.$bus.$emit('cookies-accepted');
      this.$bus.$emit('gtm-approved-cookies');
      approveCookie('approved_cookies')
      this.saveCookies(true)
    },
    saveCookies (value) {
      this.$store.dispatch('claims/set', {
        claimCode: 'cookiesAccepted',
        value: value
      });
      this.$emit('open', false)
      this.closeModal()
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.m-modal-decline {
  .sf-modal {
    --modal-width: 525px;
    z-index: 99999999999;
    position: relative;
    @include for-mobile {
      --modal-width: 100%;
    }
    p {
      color: var(--c-text-secondary);
    }
    .buttons-wrapper {
      display: flex;
    }
    .sf-button {
      margin-top: var(--spacer-25);
      &.sf-button--outline {
        margin-right: var(--spacer-15);
      }
    }
  }
}
</style>
<style lang="scss">
  .m-modal-decline {
    .sf-modal {
      .smartphone-only {
        display: none;
      }
    }
    .sf-modal__container {
      height: fit-content;
    }
  }
</style>
